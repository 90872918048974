import * as Yup from "yup";

// initialValues
const loginInitialValues = {
  email: "",
  password: "",
};

// validation schema
const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(/^(?:\d+|\S+@\S+\.\S+)$/, "Please enter a valid email*")
    .required("Email is required*"),
  password: Yup.string()
    .min(5, "Password must be at least 5 characters")
    .max(15, "Password must be at most 15 characters")
    .required("Password is required*"),
});

export { loginInitialValues, loginValidationSchema };
