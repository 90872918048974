import { KeyboardEventHandler } from "react";

export function formatDate(dateString: any) {
  const date = new Date(dateString);

  // Get the day of the month
  const day = date.getDate();

  // Get the ordinal suffix for the day
  const dayWithSuffix =
    day +
    (day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th");

  // Get the month name
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];

  // Get the year
  const year = date.getFullYear();

  return `${dayWithSuffix} ${month}, ${year}`;
}

export const numberOnly: KeyboardEventHandler<HTMLInputElement> = (event) => {
  const input = event.currentTarget.value;

  // Allow digits 1-9, the decimal point, and ensure only two digits after the decimal
  if (!/^[1-9]$/.test(event.key) && event.key !== "." && event.key !== "0") {
    event.preventDefault();
  }

  // Prevent more than one decimal point
  if (event.key === "." && input.includes(".")) {
    event.preventDefault();
  }

  // Allow only two digits after the decimal point
  if (
    input.includes(".") &&
    input.split(".")[1].length >= 2 &&
    event.key !== "Backspace"
  ) {
    event.preventDefault();
  }
};
