import React from "react";
import { toast } from "react-custom-alert";
import userGlobalConfig from "../../../utils/constants/globalConfig";
import { useAppContext } from "../../../context/authContext";
import IMAGES from "../../../utils/constants/images";
import { useFormik } from "formik";
import { INITIALVALUES, VALIDATIONS } from "../../../utils/validation";
import usePost from "../../../react-query/hooks/usePost";
import ENDPOINTS from "../../../react-query/services/endPoints";
import QUERY_KEYS from "../../../react-query/services/queryKeys";
import { BeatLoader } from "react-spinners";

const LoginPage = () => {
  // Getting setIsAuthenticated from app context
  const { setIsAuthenticated } = useAppContext();

  // State for showing or hiding password
  const [isActive, setIsActive] = React.useState(false);

  const { mutateAsync, isSuccess, isLoading } = usePost(
    ENDPOINTS.ADMIN_LOGIN,
    QUERY_KEYS.ADMIN_LOGIN_QUERY_KEY
  );

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: INITIALVALUES.loginInitialValues,
    validationSchema: VALIDATIONS.loginValidationSchema,
    onSubmit: async (values) => {
      try {
        let res = await mutateAsync(values);
        localStorage.setItem(userGlobalConfig.TOKEN, res?.token);
      } catch (error: any) {
        if (error?.response?.data?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      }
    },
  });

  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success("Login successfully");
      setIsAuthenticated(true);
      resetForm();
    }
  }, [isSuccess, resetForm, setIsAuthenticated]);

  return (
    <section className="login-section">
      <div className="container-fluid g-0">
        <div className="row g-0">
          <div className="col-md-6 d-none d-md-block">
            <div className="login-img-blk">
              <img src={IMAGES.mainTheme} alt="Banner" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="login-main-blk">
              <div className="login-form-blk">
                <h1>Log in to Cardify Admin</h1>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <div className="p-relative">
                      <input
                        type="email"
                        value={values.email}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        placeholder="Email"
                      />
                      <span className="input-icon">
                        <img src={IMAGES.mailIcon} alt="Email" />
                      </span>
                      {errors.email && touched.email && (
                        <p className="error-text">{errors.email}</p>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="p-relative">
                      <input
                        type={isActive ? "text" : "password"}
                        className="form-control"
                        value={values.password}
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Password"
                      />
                      <span className="input-icon">
                        <img src={IMAGES.lockIcon} alt="Password" />
                      </span>
                      {errors.password && touched.password && (
                        <p className="error-text">{errors.password}</p>
                      )}
                      <span onClick={() => setIsActive(!isActive)}>
                        {isActive ? (
                          <img
                            className="eye"
                            src={IMAGES.eyeOpen}
                            alt="eyeOpen"
                          />
                        ) : (
                          <img
                            className="eye"
                            src={IMAGES.eyeClose}
                            alt="eyeClose"
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="login-btn-blk">
                    <button
                      disabled={isLoading}
                      className="btn primary-btn btn-style"
                    >
                      {isLoading ? (
                        <BeatLoader color="#000000" size={10} />
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
