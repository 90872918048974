import React, { useState } from "react";
import SideBarWrapper from "../../../components/common/sidebarWrapper";
import { useNavigate } from "react-router-dom";
import RoutePath from "../../../routes/rouePath";
import { toast } from "react-custom-alert";

const Profile = () => {
  const nav = useNavigate();
  const [isActive, setIsActive] = useState(true);
  const [profileName, setProfileName] = useState("Cardify Admin");

  return (
    <SideBarWrapper
      title="Profile"
      description="View and edit the profile in this section!"
    >
      <div className="container">
        <div className="row mt-26 g-4">
          <div className="profile-wrapper">
            <div className="profile-img-blk">
              <img src={"./assets/images/theme/profile-theme.png"} alt="" />
              <p onClick={() => nav(RoutePath.CHANGEPASSWORD.path)}>
                Change Password
              </p>
            </div>
            <div className="row gx-5 gy-3 mt-5">
              <div className="col-lg-6">
                <input
                  style={{ cursor: isActive ? "not-allowed" : "auto" }}
                  readOnly={isActive ? true : false}
                  placeholder="Full Name"
                  value={profileName}
                  onChange={(e) => setProfileName(e.target.value)}
                  className="form-control common-input"
                />
              </div>
              <div className="col-lg-6">
                <input
                  style={{ cursor: "not-allowed" }}
                  placeholder="Email Address"
                  value="admin@yopmail.com"
                  readOnly
                  className="form-control common-input"
                />
              </div>
            </div>
            <div className="row gx-5 gy-3 mt-3">
              <div className="col-lg-6">
                <button
                  onClick={() => setIsActive(!isActive)}
                  className="edit-profile-btn"
                >
                  Edit Profile
                </button>
              </div>
              <div className="col-lg-6">
                <button
                  onClick={() => {
                    toast.success("Profile updated successfully");
                    setIsActive(true);
                  }}
                  disabled={isActive ? true : false}
                  className={`save-profile-btn ${isActive ? "" : "active-btn"}`}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SideBarWrapper>
  );
};

export default Profile;
