import React from "react";
import SideBarWrapper from "../../../components/common/sidebarWrapper";
import useGet from "../../../react-query/hooks/useGet";
import ENDPOINTS from "../../../react-query/services/endPoints";
import QUERY_KEYS from "../../../react-query/services/queryKeys";
import PackageTable from "../../../components/screens/packageManagement/packageTable";
import { packageTableArray } from "../../../utils/seeds";
import usePost from "../../../react-query/hooks/usePost";
import { toast } from "react-custom-alert";
import { PuffLoader } from "react-spinners";
import { ModalState } from "../../../utils/interface";
import { useFormik } from "formik";
import { INITIALVALUES, VALIDATIONS } from "../../../utils/validation";
import PackageModal from "../../../components/screens/packageManagement/packageModal";

const PackageManagement = () => {
  // Define state for modal and its initial values
  const [modalState, setModalState] = React.useState<ModalState>({
    open: false,
    modalType: "",
    actionButtonFn: () => null,
    modalTitle: "",
    actionButtonTitle: "",
    isActionButtonNotRequire: false,
    isloading: false,
  });

  const [metadata, setMetadata] = React.useState<any>({});

  // Fetches the list of packages and allows refetching the data
  const {
    data: packageListData,
    refetch: packageListRefetch,
    isFetching,
  } = useGet(ENDPOINTS.GET_PACKAGE_LIST, QUERY_KEYS.GET_USER_LIST_QUERY_KEY);

  // Create package (active/deactive)
  const { mutateAsync: CreatePackage, isLoading } = usePost(
    ENDPOINTS.CREATE_PACKAGE,
    QUERY_KEYS.CREATE_PACKAGE_STATUS_QUERY_KEY
  );
  // Updates package status (active/deactive)
  const { mutateAsync: changePlanStatus } = usePost(
    ENDPOINTS.UPDATE_PACKAGE_STATUS,
    QUERY_KEYS.UPDATE_PACKAGE_STATUS_QUERY_KEY
  );

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: INITIALVALUES.packageInitialValues,
    validationSchema: VALIDATIONS.packageValidationSchema,
    onSubmit: async () => {
      try {
        let res = await CreatePackage({
          productName: values?.name,
          productPrice: Number(values?.amount),
          productDescription: values?.benefits,
          productValidity: values?.validity,
          metadata: {
            ...metadata,
            employeeNumber: Number(values?.limit) || 0,
          },
        });
        if (res) {
          toast.success(res?.message);
          resetForm();
          setModalState({ ...modalState, open: false });
        }
      } catch (error: any) {
        if (error?.response?.data?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      }
    },
  });

  // Toggle verify/unVerify for product status change
  async function handleToggleStatus(id: string) {
    try {
      await changePlanStatus({
        productId: id,
      }).then((res) => {
        toast.success(res?.message);
        packageListRefetch();
      });
    } catch (error: any) {
      if (error?.response?.status === 404) {
        toast.error(error?.response?.data?.message);
      }
    }
  }

  // Close the modal
  const handleClosenModal = () => {
    setModalState({
      ...modalState,
      open: false,
      isActionButtonNotRequire: false,
    });
    resetForm();
    setMetadata({});
  };

  // Handle modal open event and set modal state
  const handleOpenModal = () => {
    setModalState({
      ...modalState,
      open: true,
      actionButtonFn: handleSubmit,
      modalTitle: "Create Plan",
      actionButtonTitle: "Create",
    });
  };

  // Return the appropriate modal child component based on 'modalType'
  const returnModalChild = () => {
    return (
      <PackageModal
        setFieldValue={setFieldValue}
        metadata={metadata}
        setMetadata={setMetadata}
        handleSubmit={handleSubmit}
        value={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        btnTitle={modalState.actionButtonTitle}
        closeFn={handleClosenModal}
        loading={isLoading}
        open={modalState.open}
        saveFn={modalState.actionButtonFn}
        title={modalState.modalTitle}
        errors={errors}
        touched={touched}
      />
    );
  };

  return (
    <SideBarWrapper
      title="Package Management"
      description="View and manage package subscriptions, including activity status, in this section!"
    >
      <div className="row mt-26 g-4">
        <div className="row justify-content-end">
          <button onClick={handleOpenModal} className="add-btn">
            Add Package
          </button>
        </div>
        {isFetching ? (
          <div className="d-flex hv-70 align-items-center justify-content-center">
            <PuffLoader color="#0ad5e7" size={70} />
          </div>
        ) : (
          <PackageTable
            handleToggleStatus={handleToggleStatus}
            data={packageListData?.products}
            tableArray={packageTableArray}
          />
        )}
      </div>
      {returnModalChild()}
    </SideBarWrapper>
  );
};

export default PackageManagement;
