/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import IMAGES from "../../utils/constants/images";

interface IProps {
  count: number;
  pageNumber: number;
  totalRecords: number;
  onPageChange: (page: number) => void;
}

const TablePagination = ({
  count,
  pageNumber,
  totalRecords,
  onPageChange,
}: IProps) => {
  const totalPages = Math.ceil(totalRecords / count);
  const maxVisiblePages = 4;

  const handlePrevClick = () => {
    if (pageNumber > 1) {
      onPageChange(pageNumber - 1);
    }
  };

  const handleNextClick = () => {
    if (pageNumber < totalPages) {
      onPageChange(pageNumber + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, pageNumber - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Show ellipsis before the first visible page if there are hidden pages before
    if (startPage > 1) {
      pageNumbers.push(
        <li key={1} className={`page-item ${pageNumber === 1 ? "active" : ""}`}>
          <a className="page-link" onClick={() => onPageChange(1)}>
            1
          </a>
        </li>
      );
      if (startPage > 2) {
        pageNumbers.push(
          <li key="ellipsis-start" className="page-item">
            <span className="dots">...</span>
          </li>
        );
      }
    }

    // Show the range of pages
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${pageNumber === i ? "active" : ""}`}>
          <a className="page-link" onClick={() => onPageChange(i)}>
            {i}
          </a>
        </li>
      );
    }

    // Show ellipsis after the last visible page if there are hidden pages after
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <li key="ellipsis-end" className="page-item">
            <span className="dots">...</span>
          </li>
        );
      }
      pageNumbers.push(
        <li
          key={totalPages}
          className={`page-item ${pageNumber === totalPages ? "active" : ""}`}
        >
          <a className="page-link" onClick={() => onPageChange(totalPages)}>
            {totalPages}
          </a>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="table-pagination">
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center align-items-center gap-3 gap-sm-4">
          <li className="page-item">
            <span className="page-text">
              Page {pageNumber} of {totalPages}
            </span>
          </li>
          <li className={`page-item ${pageNumber === 1 ? "disabled" : ""}`}>
            <button className="page-arrow" onClick={handlePrevClick}>
              <img src={IMAGES.leftArrowIcon} alt="arrow" />
            </button>
          </li>
          {renderPageNumbers()}
          <li
            className={`page-item ${
              pageNumber === totalPages ? "disabled" : ""
            }`}
          >
            <button className="page-arrow" onClick={handleNextClick}>
              <img src={IMAGES.rightArrowIcon} alt="arrow" />
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default TablePagination;
