import IMAGES from "../../../../utils/constants/images";
import { ICardProps } from "../../../../utils/interface";

interface IProps {
  data: any;
}
const VerifiedCardsTable = ({ data }: IProps) => {
  if (!data || data?.length === 0) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="not-found-blk vh-40">
            <h1>No card found !</h1>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="tabcontent">
      <div className="row">
        <div className="col-12">
          <div className="table-blk table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Profile Logo</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Address</th>
                  <th>Verify By</th>
                  <th>Designation</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: ICardProps, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span className="td-user-img">
                          {item?.profileImage && item?.profileImage ? (
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => window.open(item?.profileImage)}
                              src={item?.profileImage}
                              alt="User"
                            />
                          ) : (
                            <img src={IMAGES.profileLogo} alt="User" />
                          )}
                        </span>
                      </td>
                      <td>{item?.name}</td>
                      <td>{item?.email}</td>
                      <td>{item?.phoneNumber}</td>
                      <td>{item?.address}</td>
                      <td>{item?.companyName}</td>
                      <td>{item?.designation}</td>
                      <td>
                        <span
                          className={`${
                            item?.requestStatus === 0
                              ? "text-warning"
                              : item?.requestStatus === 1
                              ? "text-success "
                              : item?.requestStatus === 2 && "text-danger"
                          }`}
                        >
                          {item?.requestStatus === 0
                            ? "Rejected"
                            : item?.requestStatus === 1
                            ? "Verified"
                            : item?.requestStatus === 2 && "Unverified"}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiedCardsTable;
