import React from "react";
import SideBarWrapper from "../../../components/common/sidebarWrapper";
import { tabsArray } from "../../../utils/seeds";
import RequestsList from "../../../components/screens/companyManagement/requests/requestsList";
import VerifyCompaniesList from "../../../components/screens/companyManagement/companies/verifyCompaniesList";
import ENDPOINTS from "../../../react-query/services/endPoints";
import QUERY_KEYS from "../../../react-query/services/queryKeys";
import useGet from "../../../react-query/hooks/useGet";
import usePost from "../../../react-query/hooks/usePost";
import { toast } from "react-custom-alert";
import { ICompanyProps } from "../../../utils/interface";
import CustomAlert from "../../../components/common/alert";
import Swal from "sweetalert2";
import { useActiveTab } from "../../../context/activeTabContext";

const CompanyManagement = () => {
  // State to manage the active tab
  const { activeTab, setActiveTab } = useActiveTab();

  const { data: verifyCompanyList, refetch: companyListRefetch } = useGet(
    ENDPOINTS.GET_PROFILE_REQUESTS,
    QUERY_KEYS.GET_VERIFY_COMPANY_LIST_QUERY_KEY,
    {
      page: 1,
      limit: 10,
      status: true,
    }
  );

  const { data: requestListData, refetch: requestListRefetch } = useGet(
    ENDPOINTS.GET_PROFILE_REQUESTS,
    QUERY_KEYS.GET_PROFILE_REQUEST_LIST_QUERY_KEY,
    {
      page: 1,
      limit: 10,
    }
  );

  const { mutateAsync: changeCompanyStatus } = usePost(
    ENDPOINTS.UPDATE_COMPANY_STATUS,
    QUERY_KEYS.UPDATE_PROFILE_STATUS_QUERY_KEY
  );
  const { mutateAsync: deleteCompany } = usePost(
    ENDPOINTS.DELETE_COMPANY,
    QUERY_KEYS.DELETE_COMPANY_QUERY_KEY
  );

  // Toggle verify/unVerify for product status change
  async function handleToggleStaus(item: ICompanyProps) {
    try {
      await changeCompanyStatus({
        companyId: item?.companyId,
      }).then((res) => {
        toast.success(res?.message);
        companyListRefetch();
      });
    } catch (error) {
      console.log(error);
    }
  }

  // delete copany
  async function handleDeleteCompany(id: string) {
    CustomAlert({
      title: " Are you sure want to delete the comapany?",
      confirmButtonText: "Delete",
      icon: "question",
      onConfirm: async () => {
        try {
          await deleteCompany({
            companyId: id,
          }).then((res) => {
            toast.success(res?.message);
            companyListRefetch();
          });
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      },
    });
  }

  // Render tabs based on predefined list
  function renderTabsList() {
    return tabsArray?.map((tab, index) => (
      <button
        key={index}
        className={activeTab === tab.id ? "active" : ""}
        onClick={() => {
          setActiveTab(tab.id);
        }}
      >
        {tab.name}
      </button>
    ));
  }

  return (
    <SideBarWrapper
      description="Manage company verification and handle requests efficiently in this section!"
      title="Company Management"
    >
      <div className="container">
        <div className="row mt-5 g-4">
          <div className="col-12">
            <div className="tabs-blk">
              <div className="tabs-inner">{renderTabsList()}</div>
            </div>
            {activeTab === 0 ? (
              <RequestsList
                requestListRefetch={requestListRefetch}
                data={requestListData}
              />
            ) : (
              <VerifyCompaniesList
                companyListRefetch={companyListRefetch}
                handleDeleteCompany={handleDeleteCompany}
                handleToggleStaus={handleToggleStaus}
                data={verifyCompanyList}
              />
            )}
          </div>
        </div>
      </div>
    </SideBarWrapper>
  );
};

export default CompanyManagement;
