/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

interface IProps {
  data: any;
}

const DoumentlistTable = ({ data }: IProps) => {
  if (!data || data?.length === 0) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="not-found-blk vh-40">
            <h1>No document found !</h1>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="tabcontent">
      <div className="row">
        <div className="col-12">
          <div className="table-blk table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-start ">Document Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td className="text-start">{item?.documentName}</td>
                      <td className=" d-flex justify-content-center ">
                        <span className="td-btn-box ">
                          <button
                            onClick={() => window.open(item?.documentUrl)}
                            className="common-primary-btn "
                          >
                            View
                          </button>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoumentlistTable;
