import {
  CompanyIcon,
  DashboardIcon,
  SubscriptionIcon,
  UserIcon,
} from "../../components/common/svgIcons";
import RoutePath from "../../routes/rouePath";

export const SideBarArray = [
  {
    name: RoutePath.DASHBOARD.title,
    path: RoutePath.DASHBOARD.path,
    detailPath: "",
    svg: <DashboardIcon />,
  },
  {
    name: RoutePath.USERMANAGEMENT.title,
    path: RoutePath.USERMANAGEMENT.path,
    detailPath: RoutePath.USERDETAIL.path,
    svg: <UserIcon />,
  },
  {
    name: RoutePath.COMPANYMANAGEMENT.title,
    path: RoutePath.COMPANYMANAGEMENT.path,
    detailPath: [RoutePath.REQUESTDETAIL.path, RoutePath.COMPANYDETAIL.path],
    svg: <CompanyIcon />,
  },
  {
    name: RoutePath.PACKAGEMANAGEMENT.title,
    path: RoutePath.PACKAGEMANAGEMENT.path,
    detailPath: "",
    svg: <SubscriptionIcon />,
  },
];
