const tabsArray = [
  { id: 0, name: "Requests" },
  { id: 1, name: "Companies" },
];

const userListTableArray = [
  { name: "User Profile" },
  { name: "User Name" },
  { name: "User Email Address" },
  { name: "User Phone No." },
  { name: "Verified Number/Email" },
  { name: "User Status" },
  { name: "Action" },
];
const requestListTableArray = [
  { name: "Company logo" },
  { name: "Company Name" },
  { name: "Email" },
  { name: "Registered Address" },
  { name: "Phone No" },
  { name: "Action" },
];

const companyVerifyTableArray = [
  { name: "Company logo" },
  { name: "Company Name" },
  { name: "Email" },
  { name: "Registered Address" },
  { name: "Phone No" },
  { name: "Status" },
  { name: "Action" },
];

const packageTableArray = [
  { name: "Plan Name" },
  { name: "Plan Price" },
  { name: "Plan Description" },
  { name: "Plan Validity" },
  { name: "Active Subscriptions" },
  { name: "Plan Status" },
  { name: "Action" },
];

export {
  tabsArray,
  userListTableArray,
  requestListTableArray,
  companyVerifyTableArray,
  packageTableArray,
};
