import * as Yup from "yup";

// initialValues
const packageInitialValues = {
  name: "",
  benefits: "",
  limit: "",
  amount: "",
  validity: "",
  metadata: {},
};

// validation schema
const packageValidationSchema = Yup.object().shape({
  name: Yup.string().required("Plan name is required*"),
  benefits: Yup.string().required("Plan benifits is required*"),
  limit: Yup.string().required("Plan limit is required*"),
  amount: Yup.string().required("Plan amout is required*"),
  validity: Yup.string().required("Plan validity is required*"),
  metadata: Yup.object()
    .test("at-least-one-key", "At least one field is required*", (value) => {
      return Object.values(value || {}).some((val) => val !== "");
    })
    .required("Metadata is required*"),
});

export { packageInitialValues, packageValidationSchema };
