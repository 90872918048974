import React from "react";
import SideBarWrapper from "../../../../components/common/sidebarWrapper";
import { useLocation } from "react-router-dom";
import useGet from "../../../../react-query/hooks/useGet";
import ENDPOINTS from "../../../../react-query/services/endPoints";
import QUERY_KEYS from "../../../../react-query/services/queryKeys";
import IMAGES from "../../../../utils/constants/images";
import UserDetailInfo from "../../../../components/screens/userManagement/userDetail";
import { formatDate } from "../../../../utils/helpers";

const UserDetail = () => {
  const location = useLocation();
  const userId = location.state;

  const { data: getUserDetailist } = useGet(
    ENDPOINTS.GET_USER_DETAIL,
    QUERY_KEYS.GET_USER_DETAIL_QUERY_KEY,
    { id: userId }
  );

  return (
    <SideBarWrapper
      description="View detail of user in this section!"
      title="Users Detail"
    >
      <div className="container">
        <div className="row mt-26 g-4">
          <div className="col-12 shadow rounded-4">
            <h2 className="info-text mt-3 mb-2">User Info :</h2>
            <div className="user_detail_wrapper p-3 d-flex justify-content-start gap-4 flex-wrap">
              <div className="user_img_blk">
                {!getUserDetailist?.data?.card ? (
                  <img src={IMAGES.profileLogo} alt="" />
                ) : (
                  <img
                    src={getUserDetailist?.data?.card?.profileImage}
                    alt=""
                  />
                )}
              </div>
              <div className="user_detail">
                <div className="row row-cols-1 row-cols-md-1 row-cols-xl-3 g-3">
                  <UserDetailInfo
                    title="Name:"
                    info={getUserDetailist?.data?.user?.name}
                  />
                  <UserDetailInfo
                    title="Email:"
                    info={getUserDetailist?.data?.user?.email}
                  />
                  <UserDetailInfo
                    title="Phone Number:"
                    info={`${getUserDetailist?.data?.user?.countryCode} ${getUserDetailist?.data?.user?.phoneNumber}`}
                  />
                  <UserDetailInfo
                    title="Card Created:"
                    info={
                      getUserDetailist?.data?.user?.isCardCreated
                        ? "Created"
                        : "Not Created"
                    }
                  />
                  <UserDetailInfo
                    title="Email Verified:"
                    info={
                      getUserDetailist?.data?.user?.isEmailVerified
                        ? "Verified"
                        : "Unverified"
                    }
                  />
                  <UserDetailInfo
                    title="Number Verified:"
                    info={
                      getUserDetailist?.data?.user?.isNumberVerified
                        ? "Verified"
                        : "Unverified"
                    }
                  />
                  <UserDetailInfo
                    title="Created At:"
                    info={formatDate(getUserDetailist?.data?.user?.createdAt)}
                  />
                  <UserDetailInfo
                    title="Status:"
                    info={
                      getUserDetailist?.data?.user?.isBlocked
                        ? "Blocked"
                        : "Unblock"
                    }
                  />
                </div>
              </div>
            </div>
            {getUserDetailist?.data?.card && (
              <div>
                <h2 className="info-text mt-4 mb-2">Created Card Info :</h2>
                <div className="col-12 px-3 py-3">
                  <div className="row row-cols-1 row-cols-md-1 row-cols-xl-3 g-3">
                    <UserDetailInfo
                      title="Company Name:"
                      info={getUserDetailist?.data?.card?.companyName}
                    />
                    <UserDetailInfo
                      title="Address:"
                      info={getUserDetailist?.data?.card?.address}
                    />
                    <UserDetailInfo
                      title="City:"
                      info={getUserDetailist?.data?.card?.city}
                    />
                    <UserDetailInfo
                      title="Country:"
                      info={getUserDetailist?.data?.card?.country}
                    />
                    <UserDetailInfo
                      title="Designation:"
                      info={getUserDetailist?.data?.card?.designation}
                    />
                    <UserDetailInfo
                      title="Request Status:"
                      info={
                        getUserDetailist?.data?.card?.requestStatus === 0
                          ? "Pending"
                          : "Requested"
                      }
                    />
                    <UserDetailInfo
                      title="Created At:"
                      info={formatDate(getUserDetailist?.data?.card?.createdAt)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </SideBarWrapper>
  );
};

export default UserDetail;
