import * as Yup from "yup";

const sendMessageInitialValues = {
  message: "",
};

const sendMessageValidationSchema = Yup.object({
  message: Yup.string().required("Message is required*"),
});

export { sendMessageInitialValues, sendMessageValidationSchema };
