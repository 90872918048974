import React from "react";
import CustomTable from "../../../common/customTable";
import { requestListTableArray } from "../../../../utils/seeds";
import { ICompanyProps } from "../../../../utils/interface";
import { useNavigate } from "react-router-dom";
import RoutePath from "../../../../routes/rouePath";

interface IProps {
  data: any;
  requestListRefetch: any;
}

const RequestsList = ({ data, requestListRefetch }: IProps) => {
  // State to manage the pagination
  const [pageNumber, setPageNumber] = React.useState(1);
  const nav = useNavigate();

  // Update page number for pagination
  function handlePageChange(newPage: number) {
    setPageNumber(newPage);
  }

  function renderRequestList() {
    return data?.requests?.map((item: ICompanyProps, index: number) => {
      return (
        <tr key={index}>
          <td>
            <span className="td-user-img">
              <img
                onClick={() => window.open(item?.profileImage)}
                src={item?.profileImage}
                alt="User"
              />
            </span>
          </td>
          <td>{item?.name}</td>
          <td>{item?.email}</td>
          <td>{item?.profile_address}</td>
          <td>{`${item?.countryCode} ${item?.phoneNumber}`}</td>
          <td>
            <span className="td-btn-box">
              <span className="td-btn-box">
                <button
                  className="btn-style common-primary-btn"
                  onClick={() =>
                    nav(RoutePath.REQUESTDETAIL.path, { state: item?._id })
                  }
                >
                  View
                </button>
              </span>
            </span>
          </td>
        </tr>
      );
    });
  }

  React.useEffect(() => {
    requestListRefetch();
  }, [pageNumber]);

  return (
    <CustomTable
      notFoundMessage="No request found !"
      data={data?.requests}
      tableArray={requestListTableArray}
      count={5}
      totalRecords={data?.totalRequests}
      pageNumber={pageNumber}
      onPageChange={handlePageChange}
    >
      {renderRequestList()}
    </CustomTable>
  );
};

export default RequestsList;
