import React from "react";
import ENDPOINTS from "../../../../react-query/services/endPoints";
import QUERY_KEYS from "../../../../react-query/services/queryKeys";
import SideBarWrapper from "../../../common/sidebarWrapper";
import { useLocation } from "react-router-dom";
import useGet from "../../../../react-query/hooks/useGet";
import { IRequestProps } from "../../../../utils/interface";
import IMAGES from "../../../../utils/constants/images";
import DoumentlistTable from "../../../common/doumentlistTable";
import DetailInfo from "../../../common/detailInfo";
import VerifiedCardsTable from "./verifiedCardsTable";
import { PuffLoader } from "react-spinners";

const RequestDetail = () => {
  const location = useLocation();
  let companyId = location?.state;

  const { data, isFetching } = useGet(
    ENDPOINTS.GET_COMPANY_DETAIL,
    QUERY_KEYS.GET_COMPANY_DETAIL_LIST_QUERY_KEY,
    {
      companyId: companyId,
    }
  );
  let getcompanyListdata: IRequestProps = data?.company;

  return (
    <SideBarWrapper
      title="Company Detail"
      description="Show the detail of the company in this section!"
    >
      <div className="container">
        {isFetching ? (
          <div className="d-flex hv-70 align-items-center justify-content-center">
            <PuffLoader color="#0ad5e7" size={70} />
          </div>
        ) : (
          <div className="row mt-26 g-4">
            <div className="col-12 shadow rounded-4">
              <h2 className="info-text mt-3 mb-2">Company Info :</h2>
              <div className="user_detail_wrapper p-3 d-flex justify-content-start gap-4 flex-wrap">
                <div className="user_img_blk">
                  {!getcompanyListdata?.profileImage ? (
                    <img src={IMAGES.profileLogo} alt="" />
                  ) : (
                    <img src={getcompanyListdata?.profileImage} alt="" />
                  )}
                </div>
                <div className="user_detail">
                  <div className="row row-cols-1 row-cols-md-1 row-cols-xl-3 g-3">
                    <DetailInfo
                      title="Company Name :"
                      info={getcompanyListdata?.name}
                    />
                    <DetailInfo
                      title="Registered Address :"
                      info={getcompanyListdata?.profile_address}
                    />
                    <DetailInfo
                      title="Email Address :"
                      info={getcompanyListdata?.email}
                    />
                    <DetailInfo
                      title="Phone No (Official) :"
                      info={`${getcompanyListdata?.countryCode} ${getcompanyListdata?.phoneNumber}`}
                    />
                    <DetailInfo
                      title="Phone No (Personal) :"
                      info={`${getcompanyListdata?.countryCode} ${getcompanyListdata?.phoneNumber}`}
                    />
                    <DetailInfo
                      title="Webiste link :"
                      link={getcompanyListdata?.website}
                    />
                    <DetailInfo
                      title="Status of Verification :"
                      info={
                        getcompanyListdata?.isCompanyProfileVerified
                          ? "Verified"
                          : "Unverified"
                      }
                    />
                    <DetailInfo
                      title="Subscription Plan Name :"
                      info={getcompanyListdata?.plan?.planName}
                    />
                    <DetailInfo
                      title="Subscription Plan Amount :"
                      info={`$${getcompanyListdata?.plan?.planAmount}`}
                    />
                    <DetailInfo
                      title="Subscription Plan Status :"
                      info={getcompanyListdata?.plan?.status}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 shadow rounded-4">
              <h2 className="info-text mt-4 mb-2">Documents :</h2>
              <div className="col-12 px-3 py-3">
                <DoumentlistTable data={getcompanyListdata?.companyDocuments} />
              </div>
            </div>
            <div className="col-12 shadow rounded-4">
              <h2 className="info-text mt-4 mb-2">Verified Cards :</h2>
              <div className="col-12 px-3 py-3">
                <VerifiedCardsTable data={getcompanyListdata?.buisnessCards} />
              </div>
            </div>
          </div>
        )}
      </div>
    </SideBarWrapper>
  );
};

export default RequestDetail;
