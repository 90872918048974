// src/context/ActiveTabContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";

// Create the context
const ActiveTabContext = createContext<any>(undefined);

// Create a provider component
export const ActiveTabProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <ActiveTabContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </ActiveTabContext.Provider>
  );
};

// Create a custom hook to use the context
export const useActiveTab = () => useContext(ActiveTabContext);
