import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import RoutePath from "./rouePath";
import { useAppContext } from "../context/authContext";
import SideBar from "../components/common/sidebar";

const RouteStack = () => {
  const { isAuthenticated } = useAppContext();

  function PublicRoute() {
    if (isAuthenticated) {
      return <Navigate to={RoutePath.DASHBOARD.path} replace />;
    }
    return <Outlet />;
  }

  function PrivateRoute() {
    if (!isAuthenticated) {
      return <Navigate to={RoutePath.LOGIN.path} replace />;
    }
    return (
      <>
        <SideBar />
        <Outlet />
      </>
    );
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route index element={RoutePath.LOGIN.component} />
          <Route
            path={RoutePath.LOGIN.path}
            element={RoutePath.LOGIN.component}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path={RoutePath.DASHBOARD.path}
            element={RoutePath.DASHBOARD.component}
          />
          <Route
            path={RoutePath.USERMANAGEMENT.path}
            element={RoutePath.USERMANAGEMENT.component}
          />
          <Route
            path={RoutePath.USERDETAIL.path}
            element={RoutePath.USERDETAIL.component}
          />
          <Route
            path={RoutePath.COMPANYMANAGEMENT.path}
            element={RoutePath.COMPANYMANAGEMENT.component}
          />
          <Route
            path={RoutePath.REQUESTDETAIL.path}
            element={RoutePath.REQUESTDETAIL.component}
          />
          <Route
            path={RoutePath.COMPANYDETAIL.path}
            element={RoutePath.COMPANYDETAIL.component}
          />
          <Route
            path={RoutePath.PACKAGEMANAGEMENT.path}
            element={RoutePath.PACKAGEMANAGEMENT.component}
          />
          <Route
            path={RoutePath.PROFILE.path}
            element={RoutePath.PROFILE.component}
          />
          <Route
            path={RoutePath.CHANGEPASSWORD.path}
            element={RoutePath.CHANGEPASSWORD.component}
          />
        </Route>
        <Route
          path="*"
          element={
            <div className="d-flex vh-100 justify-content-center align-items-center">
              <img
                src="https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExMWd6bTd1N3hlZDVtcXYzOHFlbmE3enhkeGk4YTB1N3F2aWJzYWtlNiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/BFN8L8zT2VubbTYXJ8/giphy.webp"
                alt=""
              />
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteStack;
