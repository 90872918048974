import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarGraph = () => {
  // Sample data
  const data = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "",
        data: [2000, 4000, 6000, 8000, 10000, 12000],
        backgroundColor: "#3b8cf6",
        borderColor: "#0ad5e7",
        borderWidth: 1,
        borderRadius: 10,
        barThickness: 40,
      },
    ],
  };

  // Chart options
  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (tooltipItem: any) => {
            return `$${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarGraph;
