import React from "react";
import SideBarWrapper from "../../../components/common/sidebarWrapper";
import IMAGES from "../../../utils/constants/images";
import { useFormik } from "formik";
import { INITIALVALUES, VALIDATIONS } from "../../../utils/validation";
import { ClipLoader } from "react-spinners";
import usePost from "../../../react-query/hooks/usePost";
import ENDPOINTS from "../../../react-query/services/endPoints";
import QUERY_KEYS from "../../../react-query/services/queryKeys";
import { toast } from "react-custom-alert";

const ChangePassword = () => {
  const [show, setShow] = React.useState<any>({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  function handleClick(value: string) {
    setShow({ ...show, [value]: !show[value] });
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: INITIALVALUES.changePasswordInitialValues,
      validationSchema: VALIDATIONS.changePasswordValidationSchema,
      onSubmit: async () => {
        try {
          let res = await mutateAsync({
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
          });
          if (res) {
            toast.success(res?.message);
            setShow(false);
          }
        } catch (error: any) {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
          }
        }
      },
    });

  const { mutateAsync, isLoading } = usePost(
    ENDPOINTS.CHANGE_PASSWORD,
    QUERY_KEYS.CHANGE_PASSWORD_QUERY_KEY
  );

  return (
    <SideBarWrapper
      title="Change Password"
      description="Change Password in this section!"
    >
      <div className="row mt-26 g-4">
        <div className="shadow row shadow-sm p-4 bg-body g-3 mt-5 rounded z-3">
          <div className="col-lg-6 position-relative">
            <input
              type={show.oldPassword ? "text" : "password"}
              className="form-control common-input"
              placeholder={"Current Password"}
              value={values.currentPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              name={"currentPassword"}
            />
            {errors.currentPassword && touched.currentPassword && (
              <p className="error-text">{errors.currentPassword}</p>
            )}
            <span onClick={() => handleClick("oldPassword")}>
              {show.oldPassword ? (
                <img className="eye" src={IMAGES.eyeOpen} alt="eyeOpen" />
              ) : (
                <img className="eye" src={IMAGES.eyeClose} alt="eyeClose" />
              )}
            </span>
          </div>
          <div className="col-lg-6 position-relative">
            <input
              type={show.newPassword ? "text" : "password"}
              className="form-control common-input"
              placeholder={"New Password"}
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              name={"newPassword"}
            />
            {errors.newPassword && touched.newPassword && (
              <p className="error-text">{errors.newPassword}</p>
            )}
            <span onClick={() => handleClick("newPassword")}>
              {show.newPassword ? (
                <img className="eye" src={IMAGES.eyeOpen} alt="eyeOpen" />
              ) : (
                <img className="eye" src={IMAGES.eyeClose} alt="eyeClose" />
              )}
            </span>
          </div>
          <div className="col-lg-6 position-relative">
            <input
              type={show.confirmPassword ? "text" : "password"}
              className="form-control common-input"
              placeholder={"Confirm New Password"}
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              name={"confirmPassword"}
            />
            {errors.confirmPassword && touched.confirmPassword && (
              <p className="error-text">{errors.confirmPassword}</p>
            )}
            <span onClick={() => handleClick("confirmPassword")}>
              {show.confirmPassword ? (
                <img className="eye" src={IMAGES.eyeOpen} alt="eyeOpen" />
              ) : (
                <img className="eye" src={IMAGES.eyeClose} alt="eyeClose" />
              )}
            </span>
          </div>
          <div className="btn-blk">
            <button onClick={() => handleSubmit()}>
              {isLoading ? <ClipLoader size={12} /> : "Save"}
            </button>
          </div>
        </div>
      </div>
    </SideBarWrapper>
  );
};

export default ChangePassword;
