import React from "react";
import TablePagination from "./tablePagination";

interface ITableArrayItem {
  name: string;
}

interface IProps {
  data: any;
  children: React.ReactNode;
  count: number;
  pageNumber: number;
  totalRecords: number;
  onPageChange: (page: number) => void;
  tableArray: ITableArrayItem[];
  notFoundMessage: string;
}

const CustomTable = ({
  data,
  children,
  totalRecords,
  count,
  pageNumber,
  onPageChange,
  tableArray,
  notFoundMessage,
}: IProps) => {
  if (!data || data?.length === 0) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="not-found-blk">
            <h1>{notFoundMessage}</h1>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="tabcontent">
      <div className="row">
        <div className="col-12">
          <div className="table-blk table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {tableArray?.map((item: any, index) => (
                    <th key={index}>{item?.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{children}</tbody>
            </table>
          </div>
          <TablePagination
            count={count}
            pageNumber={pageNumber}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
