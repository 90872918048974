/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ICompanyProps } from "../../../../utils/interface";
import CustomTable from "../../../common/customTable";
import { companyVerifyTableArray } from "../../../../utils/seeds";
import { DeleteIcon, DetailIcon } from "../../../common/svgIcons";
import { useNavigate } from "react-router-dom";
import RoutePath from "../../../../routes/rouePath";

interface IProps {
  data: any;
  handleToggleStaus: (items: ICompanyProps) => void;
  handleDeleteCompany: (id: string) => void;
  companyListRefetch: any;
}

const VerifyCompaniesList = ({
  data,
  handleToggleStaus,
  handleDeleteCompany,
  companyListRefetch,
}: IProps) => {
  // State to manage the pagination
  const [pageNumber, setPageNumber] = React.useState(1);

  const nav = useNavigate();

  // Update page number for pagination
  function handlePageChange(newPage: number) {
    setPageNumber(newPage);
  }

  React.useEffect(() => {
    companyListRefetch();
  }, [pageNumber]);

  function renderVerifiedCompanyList() {
    return data?.requests?.map((item: ICompanyProps, index: number) => {
      return (
        <tr key={index}>
          <td>
            <span className="td-user-img">
              <img
                onClick={() => window.open(item?.profileImage)}
                src={item?.profileImage}
                alt="User"
              />
            </span>
          </td>
          <td>{item?.name}</td>
          <td>{item?.email}</td>
          <td>{item?.profile_address}</td>
          <td>{`${item?.countryCode} ${item?.phoneNumber}`}</td>
          <td>
            <span
              className={`text-capitalize text fw-bold ${
                item?.status === "verified" ? "text-success" : "text-danger"
              }`}
            >
              {item?.status}
            </span>
          </td>
          <td>
            <div className="icons-main">
              <button
                onClick={() =>
                  nav(RoutePath.COMPANYDETAIL.path, { state: item?.companyId })
                }
                className="table-trash"
              >
                <DetailIcon />
              </button>
              <button
                onClick={() => handleDeleteCompany(item?.companyId)}
                className="table-trash"
              >
                <DeleteIcon />
              </button>

              <span className="switch">
                <input
                  type="checkbox"
                  checked={item?.status === "verified" ? true : false}
                />
                <span
                  onClick={() => handleToggleStaus(item)}
                  className="slider round"
                />
              </span>
            </div>
          </td>
        </tr>
      );
    });
  }

  return (
    <CustomTable
      notFoundMessage="No verified company found !"
      data={data?.requests}
      tableArray={companyVerifyTableArray}
      count={5}
      totalRecords={data?.totalRequests}
      pageNumber={pageNumber}
      onPageChange={handlePageChange}
    >
      {renderVerifiedCompanyList()}
    </CustomTable>
  );
};

export default VerifyCompaniesList;
