interface IProps {
  title: string;
  info?: string | number;
  link?: string;
}

const DetailInfo = ({ title, info, link }: IProps) => {
  return (
    <div className="col">
      <div className="user_input_field d-flex justify-content-start align-items-start gap-3">
        <h3>{title}</h3>
        <p>{info}</p>
        <a href={link} target="_blank">
          {link}
        </a>
      </div>
    </div>
  );
};

export default DetailInfo;
