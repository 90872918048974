/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import ENDPOINTS from "../../../../react-query/services/endPoints";
import QUERY_KEYS from "../../../../react-query/services/queryKeys";
import useGet from "../../../../react-query/hooks/useGet";
import useDelete from "../../../../react-query/hooks/useDelete";
import usePost from "../../../../react-query/hooks/usePost";
import { toast } from "react-custom-alert";
import CustomAlert from "../../../../components/common/alert";
import { IUserProps } from "../../../../utils/interface";
import IMAGES from "../../../../utils/constants/images";
import {
  CrossIcon,
  DeleteIcon,
  DetailIcon,
  SuccessIcon,
} from "../../../../components/common/svgIcons";
import SideBarWrapper from "../../../../components/common/sidebarWrapper";
import CustomTable from "../../../../components/common/customTable";
import { userListTableArray } from "../../../../utils/seeds";
import { useNavigate } from "react-router-dom";
import RoutePath from "../../../../routes/rouePath";
import { PuffLoader } from "react-spinners";

const UsersManagement = () => {
  // State to manage the pagination
  const [pageNumber, setPageNumber] = React.useState(1);

  // Number of records per page
  const [count, setCount] = React.useState(5);

  // Navigation hook for redirecting to other pages
  let nav = useNavigate();

  // Fetches the list of users and allows refetching the data
  const {
    data: userListData,
    refetch: userListRefetch,
    isFetching,
  } = useGet(ENDPOINTS.GET_USERS_LIST, QUERY_KEYS.GET_USER_LIST_QUERY_KEY, {
    page: pageNumber,
    limit: count,
  });

  // muatateAsync for user deletion
  const { mutateAsync } = useDelete(
    ENDPOINTS.DELETE_USER,
    QUERY_KEYS.DELETE_USER_QUERY_KEY
  );

  // Updates user status (blocked/unblocked)
  const { mutateAsync: changeUserStatus } = usePost(
    ENDPOINTS.UPDATE_USER_STATUS,
    QUERY_KEYS.UPDATE_USER_STATUS_QUERY_KEY
  );

  // Update page number for pagination
  function handlePageChange(newPage: number) {
    setPageNumber(newPage);
  }

  // Toggle verify/unVerify for product status change
  async function handleToggleStaus(id: string) {
    try {
      await changeUserStatus({
        id: id,
      }).then((res) => {
        toast.success(res?.message);
        userListRefetch();
      });
    } catch (error: any) {
      if (error?.response?.status === 404) {
        toast.error(error?.response?.data?.message);
      }
    }
  }

  // delete user
  async function handleDeleteuser(id: string) {
    CustomAlert({
      title: " Are you sure want to delete the user?",
      confirmButtonText: "Delete",
      icon: "question",
      onConfirm: async () => {
        try {
          await mutateAsync({
            id: id,
          }).then((res) => {
            toast.success(res?.message);
            userListRefetch();
          });
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      },
    });
  }

  // Memoize the rendered user list to optimize re-rendering
  const renderUsers = () => {
    return userListData?.users?.map((item: IUserProps, index: number) => {
      return (
        <tr key={index}>
          <td>
            <span className="td-user-img">
              {item?.profileImage && item?.profileImage ? (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open(item?.profileImage)}
                  src={item?.profileImage}
                  alt="User"
                />
              ) : (
                <img src={IMAGES.profileLogo} alt="User" />
              )}
            </span>
          </td>
          <td>{item?.name}</td>
          <td>{item?.email}</td>
          <td>{`${item?.countryCode} ${item?.phoneNumber}`}</td>
          <td>
            {item?.isEmailVerified ? <SuccessIcon /> : <CrossIcon />} /
            {item?.isNumberVerified ? <SuccessIcon /> : <CrossIcon />}
          </td>

          <td>
            <span
              className={`${
                item?.isBlocked ? "status-text-danger" : "status-text-success"
              }`}
            >
              {item?.isBlocked ? "Blocked" : "Unblock"}
            </span>
          </td>
          <td>
            <span className="td-btn-box">
              <button
                onClick={() =>
                  nav(RoutePath.USERDETAIL.path, { state: item?._id })
                }
                className="table-trash"
              >
                <DetailIcon />
              </button>
              <button
                onClick={() => handleDeleteuser(item?._id)}
                className="table-trash"
              >
                <DeleteIcon />
              </button>
              <span className="switch">
                <input
                  type="checkbox"
                  checked={item?.isBlocked ? true : false}
                />
                <span
                  onClick={() => handleToggleStaus(item?._id)}
                  className="slider round"
                />
              </span>
            </span>
          </td>
        </tr>
      );
    });
  };

  useEffect(() => {
    userListRefetch();
  }, [count, pageNumber]);

  return (
    <SideBarWrapper
      description="Manage the list of users in this section!"
      title="Users Management"
    >
      <div className="row mt-26 g-4">
        <div className="col-12">
          {isFetching ? (
            <div className="d-flex hv-70 align-items-center justify-content-center">
              <PuffLoader color="#0ad5e7" size={70} />
            </div>
          ) : (
            <CustomTable
              notFoundMessage="No user found !"
              data={userListData?.users}
              tableArray={userListTableArray}
              count={count}
              totalRecords={userListData?.totalRecord}
              pageNumber={pageNumber}
              onPageChange={handlePageChange}
            >
              {renderUsers()}
            </CustomTable>
          )}
        </div>
      </div>
    </SideBarWrapper>
  );
};

export default UsersManagement;
