import CompanyDetail from "../components/screens/companyManagement/companies/companyDetail";
import RequestDetail from "../components/screens/companyManagement/requests/requestDetail";
import LoginPage from "../pages/auth/login/login";
import CompanyManagement from "../pages/screens/companyManagement";
import DashboardPage from "../pages/screens/dashboard";
import PackageManagement from "../pages/screens/packageManagement";
import ChangePassword from "../pages/screens/changePassword";
import UpdatePasswordModal from "../pages/screens/changePassword";
import UserDetail from "../pages/screens/usersManagement/userDetail";
import UsersManagement from "../pages/screens/usersManagement/userList";
import Profile from "../pages/screens/profile";

const RoutePath = {
  LOGIN: {
    component: <LoginPage />,
    path: "/login",
    title: "Login Page",
    type: "non-private",
  },
  DASHBOARD: {
    component: <DashboardPage />,
    path: "/dashboard",
    title: "Dashboard",
    type: "private",
  },
  USERMANAGEMENT: {
    component: <UsersManagement />,
    path: "/usesmanagement",
    title: "User Management",
    type: "private",
  },
  USERDETAIL: {
    component: <UserDetail />,
    path: "/userdetail",
    title: "User Detail",
    type: "private",
  },
  COMPANYMANAGEMENT: {
    component: <CompanyManagement />,
    path: "/companymanagement",
    title: "Company Management",
    type: "private",
  },
  REQUESTDETAIL: {
    component: <RequestDetail />,
    path: "/requestdetail",
    title: "Request Detail",
    type: "private",
  },
  COMPANYDETAIL: {
    component: <CompanyDetail />,
    path: "/companydetail",
    title: "Company Detail",
    type: "private",
  },
  PACKAGEMANAGEMENT: {
    component: <PackageManagement />,
    path: "/packagemanagement",
    title: "Package Management",
    type: "private",
  },
  PROFILE: {
    component: <Profile />,
    path: "/profile",
    title: "Profile",
    type: "private",
  },
  CHANGEPASSWORD: {
    component: <ChangePassword />,
    path: "/changepassword",
    title: "Change Password",
    type: "private",
  },
};

export default RoutePath;
