import React, { createContext, useContext, useState } from "react";
import { toast } from "react-custom-alert";
import userGlobalConfig from "../utils/constants/globalConfig";

const AppContext = createContext<any>(null);

interface IProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: IProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem(userGlobalConfig.TOKEN) ? true : false
  );

  const isLoggedOut = () => {
    setIsAuthenticated(false);
    localStorage.clear();
    toast.success("Logout Successfully");
  };

  return (
    <AppContext.Provider
      value={{ isAuthenticated, setIsAuthenticated, isLoggedOut }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
