/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import userGlobalConfig from "../../utils/constants/globalConfig";
import { toast } from "react-custom-alert";

const preFix = "v1/";

const baseUrl =
  process.env.REACT_APP_MODE === "PRO"
    ? process.env.REACT_APP_LIVE_BASE_URL
    : process.env.REACT_APP_DEVELOP_BASE_URL;

const endPointBaseUrl = baseUrl + preFix;

const axiosInstance = axios.create({
  baseURL: endPointBaseUrl,
  headers: undefined,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(userGlobalConfig.TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      toast.error("Session Expired");
      localStorage.removeItem(userGlobalConfig.TOKEN);
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export { axiosInstance };
