/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import IMAGES from "../../utils/constants/images";
import { useSideBarContext } from "../../context/sidebarContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/authContext";
import { SideBarArray } from "../../utils/seeds/sidebarArray";
import RoutePath from "../../routes/rouePath";
import CustomAlert from "./alert";

const SideBar = () => {
  // Get the isLoggedOut function from the authentication context
  const { isLoggedOut } = useAppContext();

  // Get the topBar and toggleSidebar from the sidebar context
  const { topBar, toggleSidebar } = useSideBarContext();

  // Get the current location object for routing
  const location = useLocation();

  // Function to determine if a link is active based on its pathname
  const nav = useNavigate();

  // Function to determine if a link is active based on its pathname
  const isLinkActive = (pathname: any) => {
    if (Array.isArray(pathname)) {
      return pathname.some((p) => location.pathname === p);
    }
    return location.pathname === pathname;
  };

  // Navigate to the provided path
  const handleNavigation = (path: string) => {
    nav(path);
    window.innerWidth <= 992 && toggleSidebar();
  };

  const zIndex = window.innerWidth <= 992 ? 111 : 1;

  // Function to handle user logout with confirmation prompt
  function handleLogout() {
    CustomAlert({
      title: "Are you sure want to logout?",
      confirmButtonText: "Logout",
      icon: "question",
      onConfirm: () => {
        isLoggedOut();
      },
    });
  }

  return (
    <div
      className={`side-navbar ${topBar ? "side side-width" : ""}`}
      style={{ zIndex }}
    >
      <ul className="nav flex-column">
        <li className="nav-item">
          <div className="logo-blk">
            <div className="logo">
              <img
                src={IMAGES.mainLogo}
                onClick={() => handleNavigation(RoutePath.DASHBOARD.path)}
                alt="Logo"
              />
            </div>
            <button
              className={
                window.innerWidth <= 992 ? "close-btn" : " close-btn d-none"
              }
              onClick={toggleSidebar}
            />
          </div>
        </li>
        {SideBarArray?.map((item) => (
          <li className="nav-item">
            <a
              className={
                isLinkActive(item?.path) || isLinkActive(item?.detailPath)
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => handleNavigation(item?.path)}
            >
              {item?.svg}
              <span className="nav-text">{item?.name}</span>
            </a>
          </li>
        ))}
      </ul>
      <ul className="nav flex-column">
        <li className="bottom-nav-item">
          <a
            className={
              isLinkActive(RoutePath.CHANGEPASSWORD.path)
                ? "nav-link active"
                : "nav-link"
            }
            onClick={() => handleNavigation(RoutePath.CHANGEPASSWORD.path)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
              id="change-pins"
              width={20}
              height={20}
            >
              <g>
                <path d="M53,56H11a7,7,0,0,1-7-7V23a7,7,0,0,1,7-7H37a1,1,0,0,1,0,2H11a5,5,0,0,0-5,5V49a5,5,0,0,0,5,5H53a5,5,0,0,0,5-5V30a1,1,0,0,1,2,0V49A7,7,0,0,1,53,56Zm-4-6a1,1,0,0,1-1-1V47.91l-.77.77a1,1,0,0,1-1.41-1.41l.77-.77H45.5a1,1,0,0,1,0-2h1.09l-.77-.77a1,1,0,0,1,1.41-1.41l.77.77V42a1,1,0,0,1,2,0v1.09l.77-.77a1,1,0,0,1,1.41,1.41l-.77.77H52.5a1,1,0,0,1,0,2H51.41l.77.77a1,1,0,0,1-1.41,1.41L50,47.91V49A1,1,0,0,1,49,50ZM37,50a1,1,0,0,1-1-1V47.91l-.77.77a1,1,0,0,1-1.41-1.41l.77-.77H33.5a1,1,0,0,1,0-2h1.09l-.77-.77a1,1,0,0,1,1.41-1.41l.77.77V42a1,1,0,0,1,2,0v1.09l.77-.77a1,1,0,0,1,1.41,1.41l-.77.77H40.5a1,1,0,0,1,0,2H39.41l.77.77a1,1,0,0,1-1.41,1.41L38,47.91V49A1,1,0,0,1,37,50ZM25,50a1,1,0,0,1-1-1V47.91l-.77.77a1,1,0,1,1-1.41-1.41l.77-.77H21.5a1,1,0,0,1,0-2h1.09l-.77-.77a1,1,0,1,1,1.41-1.41l.77.77V42a1,1,0,0,1,2,0v1.09l.77-.77a1,1,0,1,1,1.41,1.41l-.77.77H28.5a1,1,0,0,1,0,2H27.41l.77.77a1,1,0,1,1-1.41,1.41L26,47.91V49A1,1,0,0,1,25,50ZM13,50a1,1,0,0,1-1-1V47.91l-.77.77a1,1,0,0,1-1.41-1.41l.77-.77H9.5a1,1,0,0,1,0-2h1.09l-.77-.77a1,1,0,0,1,1.41-1.41l.77.77V42a1,1,0,0,1,2,0v1.09l.77-.77a1,1,0,0,1,1.41,1.41l-.77.77H16.5a1,1,0,0,1,0,2H15.41l.77.77a1,1,0,1,1-1.41,1.41L14,47.91V49A1,1,0,0,1,13,50ZM39,36.21a1,1,0,0,1-.56-.17,1,1,0,0,1-.44-.82l0-5.33a.93.93,0,0,1,.17-.56L50.7,10.56h0l1.66-2.48A4,4,0,1,1,59,12.51h0L44.77,33.78a.93.93,0,0,1-.46.37l-4.94,2A1.16,1.16,0,0,1,39,36.21Zm1-6,0,3.55,3.29-1.32L55.13,14.72l-3.32-2.23Zm13-19.35,3.33,2.23,1.11-1.66A2,2,0,1,0,54,9.17ZM58.19,12h0Z"></path>
              </g>
            </svg>
            <span className="nav-text">Change Password</span>
          </a>
        </li>
        <li className="bottom-nav-item">
          <a className="nav-link" onClick={handleLogout}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clipPath="url(#clip0_5_768)">
                <path
                  d="M4.16665 18.3334C3.94563 18.3334 3.73367 18.2456 3.57739 18.0893C3.42111 17.933 3.33331 17.721 3.33331 17.5V2.50002C3.33331 2.27901 3.42111 2.06704 3.57739 1.91076C3.73367 1.75448 3.94563 1.66669 4.16665 1.66669H15.8333C16.0543 1.66669 16.2663 1.75448 16.4226 1.91076C16.5788 2.06704 16.6666 2.27901 16.6666 2.50002V5.00002H15V3.33335H4.99998V16.6667H15V15H16.6666V17.5C16.6666 17.721 16.5788 17.933 16.4226 18.0893C16.2663 18.2456 16.0543 18.3334 15.8333 18.3334H4.16665ZM15 13.3334V10.8334H9.16665V9.16669H15V6.66669L19.1666 10L15 13.3334Z"
                  fill="#464255"
                />
              </g>
              <defs>
                <clipPath id="clip0_5_768">
                  <rect width={20} height={20} fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="nav-text">Log Out </span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
