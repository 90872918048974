import React from "react";
import CustomModal from "../../common/modal";
import { numberOnly } from "../../../utils/helpers";

interface IProps {
  title: string;
  btnTitle: string;
  closeFn: () => void;
  saveFn: () => void;
  open: boolean;
  loading: boolean;
  value: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  handleSubmit: any;
  metadata: object;
  setMetadata: any;
  setFieldValue: any;
}

const PackageModal = ({
  closeFn,
  loading,
  open,
  saveFn,
  title,
  btnTitle,
  errors,
  handleBlur,
  handleChange,
  touched,
  value,
  handleSubmit,
  metadata,
  setMetadata,
  setFieldValue,
}: IProps) => {
  const [newKey, setNewKey] = React.useState("");
  const [newValue, setNewValue] = React.useState("");

  // Handle adding metadata
  const handleAddMetadata = () => {
    if (newKey && newValue) {
      // Create a new metadata object by adding the new key-value pair
      const updatedMetadata = {
        ...metadata, // Spread the previous metadata
        [newKey]: newValue, // Add the new key-value pair
      };

      // Update the state with the new metadata
      setMetadata(updatedMetadata);

      // Update the form value immediately
      setFieldValue("metadata", updatedMetadata);

      // Clear the input fields
      setNewKey("");
      setNewValue("");
    }
  };

  // Handle removing metadata
  const handleRemoveMetadata = (key: string) => {
    setMetadata((prevMetadata: any) => {
      const updatedMetadata = { ...prevMetadata };
      delete updatedMetadata[key];
      setFieldValue("metadata", updatedMetadata);
      return updatedMetadata;
    });
  };

  return (
    <CustomModal
      btnTitle={btnTitle}
      closeModalFn={closeFn}
      loading={loading}
      saveFn={saveFn}
      open={open}
      title={title}
    >
      <form onSubmit={handleSubmit}>
        <div className="row gx-5 gy-3 justify-content-center mt-4">
          <div className="col-sm-6">
            <input
              className="form-control common-input"
              placeholder={" Plan Name"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={value?.name}
              name="name"
            />
            {errors.name && touched.name && (
              <p className="error-text">{errors.name}</p>
            )}
          </div>
          <div className="col-sm-6">
            <input
              className="form-control common-input"
              placeholder={" Plan Amount"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={value?.amount}
              name="amount"
              onKeyPress={numberOnly}
            />
            {errors.amount && touched.amount && (
              <p className="error-text">{errors.amount}</p>
            )}
          </div>
          <div className="col-sm-6">
            <select
              name="validity"
              value={value?.validity}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-select common-input"
            >
              <option disabled value="">
                Select Plan Validity
              </option>
              <option value="year">Year</option>
              <option value="month">Month</option>
              <option value="week">Week</option>
            </select>
            {errors.validity && touched.validity && (
              <p className="error-text">{errors.validity}</p>
            )}
          </div>
          <div className="col-sm-6">
            <input
              className="form-control common-input"
              placeholder="Plan Limit"
              onChange={handleChange}
              onBlur={handleBlur}
              value={value?.limit}
              name={"limit"}
            />
            {errors.limit && touched.limit && (
              <p className="error-text">{errors.limit}</p>
            )}
          </div>
          <div className="col-sm-12">
            <textarea
              rows={4}
              className="form-control common-input"
              name="benefits"
              placeholder="Enter Benefits Here..."
              onChange={handleChange}
              onBlur={handleBlur}
              value={value.benefits}
            />
            {errors.benefits && touched.benefits && (
              <p className="error-text">{errors.benefits}</p>
            )}
          </div>
          <div className="row mt-3 align-items-center">
            <div className="col-sm-5">
              <input
                className="form-control common-input me-2"
                placeholder="Key"
                value={newKey}
                onChange={(e) => setNewKey(e.target.value)}
              />
            </div>
            <div className="col-sm-5">
              <input
                className="form-control common-input me-2"
                placeholder="Value"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
              />
            </div>
            <div className="col-sm-2">
              <button
                type="button"
                className="btn btn-info text-white py-2"
                onClick={handleAddMetadata}
              >
                Add Field
              </button>
            </div>
            {errors.metadata && touched.metadata && (
              <p className="error-text">{errors.metadata}</p>
            )}
          </div>
          <div className="col-sm-12 mt-3">
            <h4>Meta Data:</h4>
            <ul className="list-unstyled gap-2 d-flex flex-column">
              {Object.entries(metadata).map(([key, value]) => (
                <li
                  key={key}
                  className="d-flex justify-content-between align-items-center"
                >
                  <p>
                    <strong>{key} :</strong> {value}
                  </p>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleRemoveMetadata(key)}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </form>
    </CustomModal>
  );
};

export default PackageModal;
